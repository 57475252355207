import styled from "styled-components";

export const Copyright = styled(({ className }) => {
  const today = new Date();
  const year = today.getFullYear();
  return <div className={className}>&reg;{year} CDK&nbsp;Global,&nbsp;LLC</div>;
})`
  font-size: 12px;
  color: #888;
  margin-top: 32px;
`;
