import styled from "styled-components";
import { useUser } from "store/useUser";
import { makeEntriesList } from "utils/makeEntriesList";
import { SimpleEntryGroup } from "./EntryGroup/EntryGroup";
import { Text } from "./Text";
import { IconFavorite } from "cdk-radial";

export const StyledMyApplications = styled(
  ({ className, appsList, hideDescription }) => {
    const { currentUser, setFavorite, removeFavorite } = useUser((state) => ({
      currentUser: state.currentUser,
      setFavorite: state.addFavoriteAppByUser,
      isFavorite: state.isFavoriteApp,
      removeFavorite: state.removeFavoriteAppByUser,
    }));
    const appsEntries = makeEntriesList({
      list: appsList,
      isFavorite: () => true,
      setFavorite,
      removeFavorite,
      user: currentUser,
    });

    const actionMenuOptions = [
      {
        label: "Remove from MyUnify",
        value: "remove",
        icon: <IconFavorite />,
        onClick: (appId) => removeFavorite(currentUser, appId),
      },
    ];
    return (
      <SimpleEntryGroup
        customKey="MyApplications"
        actionMenuOptions={actionMenuOptions}
        className={className}
        title="My Applications"
        entries={appsEntries}
        hasDescription={false}
        hasFavorite={false}
        hasActionMenu={true}
      />
    );
  }
)`
  & > ${Text.Heading3} {
    padding-bottom: 16px;
  }
  gap: 24px;
`;
export const MyApplications = (props) => <StyledMyApplications {...props} />;
