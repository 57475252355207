import React, { useState } from "react";
import { LoginCard } from "./components/LoginCard";
import styled from "styled-components";

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding-left: 32px;
`;
const Logo = styled.div`
  background: #fff;
  color: #000;
  width: 94px;
  height: 94px;
  font-size: 28px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  letter-spacing: 1px;
`;
const Title = styled.div`
  font-size: 32px;
  text-transform: uppercase;
  font-family: Montserrat;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2px;
`;
const LoginBody = styled.div``;
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 120px;
  margin-top: 120px;
`;

const StyledLogin = styled(({ className }) => {
  // const [form, setForm] = useState({});
  const [form] = useState({});

  return (
    <div className={className}>
      <Container>
        <LogoBox>
          <Logo>CDK</Logo>
          <Title>Global</Title>
        </LogoBox>
        <LoginBody>
          <LoginCard form={form} />
        </LoginBody>
      </Container>
    </div>
  );
})`
  display: flex;
  align-items: flex-start;

  justify-content: center;
  height: 100vh;
  background: #000;
`;

export const Login = (props) => <StyledLogin {...props} />;
