import { entries } from "store/entries";

export const getFavoriteWorkflows = (user) => {
  if (!user) return undefined;
  if (user === 0) return undefined;

  const list = {};
  for (const entry of Array.from(entries.values())) {
    if (entry.type === "workflow") {
      if (user.favoriteWorkflows.includes(entry.name)) {
        if (list[entry.app]) {
          list[entry.app] = [...list[entry.app], entry];
        } else {
          list[entry.app] = [entry];
        }
      }
    }
  }
  return list;
};
