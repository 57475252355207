import { theme } from "cdk-radial";

import cdkDriveLandingPage from "../pngs/Drive_Landing_Page.png";
import Feb2023CDKDriveSaaS from "../pngs/CDK-Drive-SaaS.png";
import Feb2023ELeadDesklog from "../pngs/eLead-Desklog.png";
import Feb2023CDKService from "../pngs/CDK-Service.png";
import Feb2023CommonAdmin from "../pngs/Common-Admin.png";
import cdkRoadsterLandingPage from "../pngs/Roadster_Landing_Page.png";
import FortellisLanding from "../pngs/FortellisLandingMax.png";
import Feb2023DriveWorkflowMenu1099 from "../pngs/Drive-Workflow-Menu-1099.png";
import Feb2023DriveWorkflowMenuApInvoice from "../pngs/Drive-Workflow-Menu-AP-Invoice.png";
import Feb2023DriveWorkflowMenuApPayments from "../pngs/Drive-Workflow-Menu-AP-Payments.png";
import Feb2023ELeadCRM from "../pngs/eLead-CRM.png";
import Feb2023ELeadAddProspect from "../pngs/eLead-Add-Prospect.png";
import Feb2023ELeadDailyOrganizer from "../pngs/eLead-Daily-Organizer.png";
import Feb2023ELeadSearch from "../pngs/eLead-Search.png";
import Feb2023ELeadToday from "../pngs/eLead-Today.png";
import RoadsterNewAdminMax from "../pngs/RoadsterNewAdminMax.png";
import RoadsterStorefrontMax from "../pngs/RoadsterStorefrontMax.png";
import RoadsterCustomersMax from "../pngs/RoadsterCustomersMax.png";
import RoadsterPricingMax from "../pngs/RoadsterPricingMax.png";
import RoadsterCustomerCheckinMax from "../pngs/RoadsterCustomerCheckinMax.png";
import Feb2023ServiceAdmin from "../pngs/Service-Admin.png";
import FortellisMarketPlace from "../pngs/FortellisMarketplace.png";
import Feb2023FortellisAppManagement from "../pngs/Fortellis-App-Management.png";
import cdkUniversity from "../pngs/CDK-University.png";

const applications = [
  [
    "CDK Drive",
    {
      content: "This will be the Drive Application.",
      description:
        "The industry leading dealer management solution. Access your desktop Drive applications here.",
      group: "dms",

      hasDomains: true,
      image: cdkDriveLandingPage,
      initials: { initials: "Dr", case: "initial" },
      name: "CDK Drive",
      type: "app",
      width: "1280px",
    },
  ],
  [
    "CDK Global University",
    {
      name: "CDK Global University",
      initials: { initials: "Un", case: "initial" },
      description: "Learn all about CDK Global products and how to use them.",
      content: "Study CDK Global products",
      image: cdkUniversity,
      group: "cdk",
      groupCustom: { borderColorDarkMode: theme.color.gray[800].value },
      hasDomains: false,
      type: "app",
    },
  ],

  [
    "CDK Drive SaaS",
    {
      name: "CDK Drive SaaS",
      initials: { initials: "Dr", case: "initial" },
      description:
        "A flexible, powerful dealer management system (DMS) tailored to multiple dealership locations",
      content: "This will be the Drive Application.",
      image: Feb2023CDKDriveSaaS,
      width: "1280px",
      group: "dms",
      type: "app",
    },
  ],
  [
    "CDK Elead",
    {
      name: "CDK Elead",
      initials: { initials: "CRM", case: "upper" },
      description:
        "Easily view, manage, and analyze customer data to build strong relationships and close sales",
      image: Feb2023ELeadDesklog,
      width: "1280px",
      type: "app",
      group: "crm",
    },
  ],
  [
    "CDK Service",
    {
      name: "CDK Service",
      initials: { initials: "Se", case: "initial" },

      description:
        "Quickly schedule service appointments for one store or the entire dealer group from a single BDC",
      content: "This will be CDK Service",
      image: Feb2023CDKService,
      width: "1280px",
      group: "fixed ops",
      type: "app",
    },
  ],
  [
    "Common Admin",
    {
      name: "Common Admin",
      initials: { initials: "Ca", case: "initial" },
      description: "Manage CDK roles and permissions",
      image: Feb2023CommonAdmin,
      width: "1280px",
      group: "it solutions",
      type: "app",
    },
  ],
  [
    "Roadster",
    {
      name: "Roadster",
      initials: { initials: "RD", case: "initial" },
      description:
        "The industry's first omnichannel vehicle-buying process with dealers at its heart",
      url: "roadster.redcarbon.site",
      image: cdkRoadsterLandingPage,
      group: "digital retailing",
      groupCustom: { borderColorDarkMode: theme.color.gray[800].value },
      type: "app",
    },
  ],
  [
    "Fortellis",
    {
      name: "Fortellis",
      initials: { initials: "Fo", case: "initial" },
      description:
        "Fortellis is a technology platform that allows the automotive industry to leverage, build, innovate and integrate apps and workflows to transform business",
      image: FortellisLanding,
      width: "1280px",
      group: "fortellis",
      type: "app",
    },
  ],
];

const workflows = [
  [
    "1099",
    {
      app: "CDK Drive SaaS",
      description: "Manage all 1099s in one place",
      domain: "Accounting",
      name: "1099",
      image: Feb2023DriveWorkflowMenu1099,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Ap Invoice",
    {
      app: "CDK Drive SaaS",

      description: "Manage all invoices in one place",
      domain: "Accounting",
      name: "AP Invoice",
      image: Feb2023DriveWorkflowMenuApInvoice,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "AP Payments",
    {
      app: "CDK Drive SaaS",
      description: "Integrated electronic invoice payment",
      domain: "Accounting",
      name: "AP Payments",
      image: Feb2023DriveWorkflowMenuApPayments,
      type: "workflow",
      width: "1280px',",
    },
  ],

  [
    "Workflow",
    {
      app: "CDK Elead",
      description: "Create, view, and manage workflows.",
      name: "Workflow",
      image: Feb2023ELeadCRM,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Add Prospect",
    {
      app: "CDK Elead",
      description: "The sales lifecycle of your customer, all in one place",
      name: "Add Prospect",
      image: Feb2023ELeadAddProspect,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Desklog",
    {
      app: "CDK Elead",
      description: "The sales lifecycle of your customer, all in one place",
      name: "Desklog",
      image: Feb2023ELeadDesklog,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Daily Organizer",
    {
      app: "CDK Elead",
      description: "The sales lifecycle of your customer, all in one place.",
      name: "Daily Organizer",
      // image: EleadOrganizerMax,
      image: Feb2023ELeadDailyOrganizer,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Search",
    {
      app: "CDK Elead",
      description: "Quickly find customer records",
      name: "Search",
      image: Feb2023ELeadSearch,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Today",
    {
      app: "CDK Elead",
      description: "The sales lifecycle of your customer, all in one place",
      name: "Today",
      image: Feb2023ELeadToday,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Admin Launch",
    {
      app: "Roadster",
      description: "Administrative overview and functions",
      name: "Admin Launch",
      image: RoadsterNewAdminMax,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Storefront",
    {
      app: "Roadster",
      description: "Trade-in and appraisal",
      name: "Storefront",
      image: RoadsterStorefrontMax,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "customer",
    {
      app: "Roadster",
      description: "Trade-in and appraisal",
      name: "Customers",
      image: RoadsterCustomersMax,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Pricing",
    {
      app: "Roadster",
      description: "Trade-in and appraisal",
      name: "Pricing",
      image: RoadsterPricingMax,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "customer Check-in",
    {
      app: "Roadster",
      description: "Trade-in and appraisal",
      name: "Customer Check-in",
      image: RoadsterCustomerCheckinMax,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "RO Dashboard",
    {
      app: "CDK Service",
      description: "Repair Order Dashboard",
      name: "RO Dashboard",
      image: Feb2023ServiceAdmin,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Marketplace",
    {
      app: "Fortellis",
      description: "Discover the latest services and APIs to enhance your DMS",
      name: "Marketplace",
      image: FortellisMarketPlace,
      type: "workflow",
      width: "1280px",
    },
  ],
  [
    "Subscription Portal",
    {
      app: "Fortellis",
      description: "Manage your API subscriptions in one place",
      name: "Subscription Portal",
      image: Feb2023FortellisAppManagement,
      type: "workflow",
      width: "1280px",
    },
  ],
];

export const entries = new Map([...applications, ...workflows]);

export const workflowsList = Array.from(entries.values())
  .filter((item) => item.type === "workflow")
  .sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
