import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSettings } from "store/useSettings";
import { useUser } from "store/useUser";
import {
  Input,
  Checkbox,
  CHECKBOX_LABEL_POSITIONS,
  PasswordInput,
  PrimaryButton,
  TextButton,
  theme,
} from "cdk-radial";
import { Header } from "./Header";
import { Disclaimer } from "./Disclaimer";
import { Copyright } from "./Copyright";
import styled, { ThemeProvider } from "styled-components";

const LoginButton = styled(PrimaryButton)`
  margin: 16px 0 0;
  && {
    background-color: black;
    color: white;
  }
`;

const Password = styled.p``;

const ResetCache = styled(TextButton)`
  opacity: 0.3;
  :hover {
    opacity: 1;
  }
`;

const FakeAnchor = styled.span`
  color: black;
  font-weight: 600;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const LoginCard = styled(({ className, form, handler }) => {
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const setCurrentUser = useUser((state) => state.setCurrentUser);

  const updateEmail = (event) => {
    setEmail(event.target.value);
  };
  const updateRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  const updatePassword = (event) => {
    setPassword(event.target.value);
  };

  const login = () => {
    setCurrentUser(1);
    setTimeout(() => navigate("/"), 0);
  };

  const resetLocalStorage = () => {
    useUser.persist.clearStorage();
    useSettings.persist.clearStorage();
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={className}>
        <Header>Sign In</Header>
        <Input
          label="Email"
          name="Email"
          id="Email"
          value={email}
          placeholder="Example@email.com"
          onChange={updateEmail}
        />
        <Checkbox
          checked={rememberMe}
          label="Remember me"
          labelPosition={CHECKBOX_LABEL_POSITIONS.AFTER}
          name="remember-me"
          onChange={updateRememberMe}
        />
        <PasswordInput
          css={`
            margin-top: 16px;
            margin-bottom: 32px;
          `}
          id="password-input-simple"
          label="Password Input"
          name="password-input-simple"
          onChange={updatePassword}
          value={password}
        />

        <LoginButton onClick={login} text="Sign In" />
        <Disclaimer>
          <Password>
            <FakeAnchor>Forgot your password?</FakeAnchor>
          </Password>
          <p>
            Forgot your email or need to request an account?
            <br />
            Please contact your enterprise administrator.
          </p>
          <p>
            For additional assistance, please contact{" "}
            <FakeAnchor>CDK&nbsp;Support</FakeAnchor>.
          </p>
          <Copyright />
          <ResetCache text="reset cache" onClick={resetLocalStorage} />
        </Disclaimer>
      </div>
    </ThemeProvider>
  );
})`
  font-family: Roboto, sans-serif;
  width: 432px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
`;
