import { create } from "zustand";

const dealershipExperiencePlatform = [
  {
    name: "General Ledger",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "Accounts Payable",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "Multi-company Accounting",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "General Reporting",
    designSystem: ["RDS 6"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Service Invoicing",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "Parts Invoicing",
    designSystem: ["CSS/Flex"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Tech Workbench",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "Financial Reporting",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Accounts Receivable",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "F&I",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Drive Features",
  },
  {
    name: "Vehicle Inventory",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Parts Inventory",
    designSystem: ["CSS/Flex"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Doc Storage and Archiving",
    designSystem: ["RDS 6"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Forms",
    subtitle: "includes P, S, A, F&I",
    designSystem: ["RDS 6"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Digital Deal Jacket",
    subtitle: "Scan & Archive Docs",
    designSystem: ["TBD"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Roadster Storefront",
    subtitle: "Online",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Parts Scan",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Not in Drive",
  },
  {
    name: "Texting",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Not in Drive",
  },
  {
    name: "Data Your Way Access Tools",
    designSystem: ["RDS 6"],
    hasProductAnalytics: false,
    group: "Not in Drive",
  },
  {
    name: "Positive Pay",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "OEM",
    subtitle: "Updates & Integ.",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Drive Features",
  },
  {
    name: "Sales Express Desking",
    subtitle: "w/Rates & Residuals",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "E-sign",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Modern Retail",
  },
  {
    name: "Hailer",
    designSystem: ["CSS"],
    hasProductAnalytics: false,
    group: "Fixed Ops",
  },
  {
    name: "Service Scheduling",
    subtitle: "appointments",
    designSystem: ["CSS (COSA)", "RDS 6 in Store"],
    hasProductAnalytics: true,
    group: "Fixed Ops",
  },
  {
    name: "Executive Dashboard",
    designSystem: ["RDS 6"],
    hasProductAnalytics: false,
    group: "Intelligence",
  },
  {
    name: "Credit Check",
    subtitle: "access to 700 Credit",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Snap-On Level 1",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Fixed Ops",
  },
];
const modernRetailSuite = [
  {
    name: "E-Contracting",
    subtitle: "Lenders",
    designSystem: ["Roadster"],
    hasProductAnalytics: false,
    group: "Modern Retail",
  },
  {
    name: "Sales Docs",
    subtitle: "Sales Express",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Menu",
    subtitle: "Sales Express",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Roadster Storefront",
    subtitle: "Omni-channel",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "CRM",
    subtitle: "Essentials",
    designSystem: ["CSS"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Compliance",
    designSystem: ["TBD"],
    hasProductAnalytics: false,
    group: "Modern Retail",
  },
  {
    name: "Trade",
    subtitle: "Roadster",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
  {
    name: "Response",
    subtitle: "Roadster",
    designSystem: ["Roadster"],
    hasProductAnalytics: true,
    group: "Modern Retail",
  },
];

const fixedOpsSuite = [
  {
    name: "CDK Sedrvice Lane & Insepct",
    designSystem: ["RDS 6", "CSS"],
    hasProductAnalytics: true,
    group: "Fixed Ops",
  },
  {
    name: "Service View",
    designSystem: ["TBD"],
    hasProductAnalytics: true,
    group: "Fixed Ops",
  },
  {
    name: "CDK Service Pricing",
    designSystem: ["RDS 6", "CSS"],
    hasProductAnalytics: true,
    group: "Fixed Ops",
  },
  {
    name: "Enhanced Marketing Forms",
    designSystem: ["CSS"],
    hasProductAnalytics: false,
    group: "Fixed Ops",
  },
  {
    name: "COnnected Vehicle",
    subtitle: "Service",
    designSystem: ["RDS 6", "CSS"],
    hasProductAnalytics: true,
    group: "Fixed Ops",
  },
];

const intelligenceSuite = [
  {
    name: "Neuron Performance View",
    designSystem: ["RDS 6"],
    hasProductAnalytics: true,
    group: "Intelligence",
  },
];

export const useModern = create((set) => ({
  suites: {
    "Dealership Experience Platform": {
      desc: "Everything to sell, service & operate your business in a modern and digital world",
      list: dealershipExperiencePlatform,
    },
    "Modern Retail Suite": {
      desc: "Accelerate revenue and increase consumer engagement",
      list: modernRetailSuite,
    },
    "Fixed Ops Suite": {
      desc: "Build customer loyalty and maximize service revenue",
      list: fixedOpsSuite,
    },
    "Intelligence Suite": {
      desc: "Make faster and smarter decisions across the dealer groups",
      list: intelligenceSuite,
    },
  },
}));
