import styled from "styled-components";
import { useUser } from "store/useUser";
import { findEntry } from "utils/findEntry";
import { Accordion } from "./Accordion";
import { makeEntriesList } from "utils/makeEntriesList";
import { AccordionClosedWorkflow } from "./AccordionClosedWorkflow";
import { AccordionOpenWorkflow } from "./AccordionOpenWorkflow";
import { IconFavorite } from "cdk-radial";
import { EntryGroupTitle } from "./EntryGroup/EntryGroupTitle";

export const StyledMyWorkflows = styled(
  ({ className, workflowsList, hasDescription }) => {
    const {
      currentUser,
      setFavorite,
      removeFavorite,
      isOpenWorkflowSection,
      addOpenWorkflowSection,
    } = useUser((state) => ({
      currentUser: state.currentUser,
      setFavorite: state.addFavoriteWorkflowsByUser,
      isFavorite: state.isFavoriteWorkflow,
      removeFavorite: state.removeFavoriteWorkflowByUser,
      isOpenWorkflowSection: state.isOpenWorkflowSection,
      addOpenWorkflowSection: state.addOpenWorkflowSection,
      removeOpenWorkflowSection: state.removeOpenWorkflowSection,
    }));
    const actionMenuOptions = [
      {
        label: "Remove from MyUnify",
        value: "remove",
        icon: <IconFavorite />,
        onClick: (appId) => removeFavorite(currentUser, appId),
      },
    ];
    return (
      <div className={`MyWorkflows ${className}`}>
        <EntryGroupTitle>My Workflows</EntryGroupTitle>
        {Object.entries(workflowsList).map(([app, workflowEntries]) => {
          const entry = findEntry({ type: "app", name: app });
          const list = makeEntriesList({
            list: workflowEntries,
            isFavorite: () => true,
            setFavorite,
            removeFavorite,
            user: currentUser,
          });

          return (
            <Accordion
              key={`MyWorkflows-${app}`}
              contentId={app}
              isAccordionOpen={isOpenWorkflowSection(app)}
              setIsAccordionOpen={() => addOpenWorkflowSection(app)}
              contentOpen={
                <AccordionOpenWorkflow
                  workflowsList={list}
                  entry={entry}
                  actionMenuOptions={actionMenuOptions}
                />
              }
              contentClosed={<AccordionClosedWorkflow entry={entry} />}
            />
          );
        })}
      </div>
    );
  }
)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MyWorkflows = (props) => <StyledMyWorkflows {...props} />;
