import styled from "styled-components";
import { AppIcon } from "./AppIcon";
import { AppName } from "./AppName";

export const StyledAccordionClosedWorkflow = styled(({ className, entry }) => {
  const { initials, group, name } = entry;
  return (
    <div className={`WorkflowAccordionClosed ${className}`}>
      <AppIcon initials={initials} group={group} />
      <AppName>{name}</AppName>
    </div>
  );
})`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  color: ${({ theme }) => theme.color.additional.dark.value};
`;

export const AccordionClosedWorkflow = (props) => (
  <StyledAccordionClosedWorkflow {...props} />
);
