import styled from "styled-components";
import { Logo } from "./Logo";
import { Content } from "./Content";
import { Headline } from "./Headline";
import { Description } from "./Description";
import { LearnMore } from "./LearnMore";
import { Thumbnails } from "./Thumbnails";
import { useSettings } from "store/useSettings";

export const Pane = styled(
  ({
    className,
    pane,
    bannerWidth,
    paneArray,
    container,
    onThumbnailClick,
  }) => {
    const { headline, description } = pane;
    const isDarkMode = useSettings((state) => state.isDarkMode);
    const logo = isDarkMode ? pane.svg.dark : pane.svg.light;
    return (
      <div className={`CarouselPane ${className}`}>
        <Logo>{logo}</Logo>
        <Content>
          <Headline>{headline}</Headline>
          <Description>{description}</Description>
          <LearnMore>Learn More</LearnMore>
          <Thumbnails
            paneArray={paneArray}
            container={container}
            onThumbnailClick={onThumbnailClick}
          />
        </Content>
      </div>
    );
  }
)`
  display: flex;
  width: ${({ bannerWidth }) => {
    const browserWidth = document.documentElement.clientWidth;
    return browserWidth > 1280 ? "1280px" : `${browserWidth}px`; // return bannerWidth
  }};
  flex-shrink: 0;
  ${Logo} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
  }
  ${Content} {
    background: ${({ theme }) => {
      const isDarkMode = useSettings((state) => state.isDarkMode);
      if (isDarkMode) {
        return theme.color.additional.alwaysBlack.value;
      } else {
        return theme.color.gray[100].value;
      }
    }};
    position: relative;
  }
`;
