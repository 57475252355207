import { entries } from "store/entries";

export const getAllApps = () => {
  const list = Array.from(entries.values())
    .filter((item) => item.type === "app")
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  return list;
};
