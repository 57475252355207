import styled from "styled-components";
import {
  StyledEntryGroup,
  EntryGroupWithDomains,
} from "./EntryGroup/EntryGroup";
import { findEntry } from "../utils/findEntry";
import { Text } from "./Text";
import { IconFavorite } from "cdk-radial";
import { useUser } from "store/useUser";

const AllWorkflowsHeading = styled(Text.Heading3)`
  color: ${({ theme }) => theme.color.additional.dark.value};
`;

export const StyledAllWorkflows = styled(({ className, list }) => {
  const { isFavorite, toggleFavorite } = useUser((state) => ({
    isFavorite: state.isFavoriteWorkflowCurrentUser,
    toggleFavorite: state.toggleFavoriteWorkflow,
  }));
  const actionMenuOptions = [
    {
      label: (flowId) =>
        isFavorite(flowId) ? "Remove from MyUnify" : "Add to MyUnify",
      value: "toggle",
      icon: <IconFavorite />,
      onClick: (flowId) => toggleFavorite(flowId),
    },
  ];
  return (
    <div className={`AllWorkflows ${className}`}>
      <AllWorkflowsHeading>All Workflows</AllWorkflowsHeading>
      {Object.entries(list).map(([app, workflowEntries]) => {
        const appEntry = findEntry({ type: "app", name: app });

        return (
          <EntryGroupWithDomains
            customKey="All Workfows"
            key={JSON.stringify(app)}
            app={appEntry}
            actionMenuOptions={actionMenuOptions}
            entries={workflowEntries}
            hasDescription={true}
            hasFavorite={false}
            hasActionMenu={true}
            hasIcon={false}
            hasAppSection={true}
          />
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;

  ${StyledEntryGroup} {
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.color.gray[200].value};
    border-radius: 4px;
  }
`;

export const AllWorkflows = (props) => <StyledAllWorkflows {...props} />;
