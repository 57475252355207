import React from "react";
import styled from "styled-components";
import { EntryCard } from "../EntryCard/EntryCard";
import { makeEntriesList } from "utils/makeEntriesList";
import { useUser } from "store/useUser";
import { EntryGroupTitle } from "./EntryGroupTitle";
import { Entries } from "./Entries";
import { Domain } from "./Domain";
import { AppSection } from "./AppSection";

export const SimpleEntryGroup = styled(
  ({
    className,
    entries,
    title,
    customKey,
    actionMenuOptions,
    hasActionMenu = false,
    hasDescription = true,
    hasFavorite = true,
    hasIcon = true,
    hasTitle = true,
  }) => {
    return (
      <div className={className}>
        {hasTitle && <EntryGroupTitle>{title}</EntryGroupTitle>}
        <Entries>
          {entries.map((entry) => {
            return (
              <EntryCard
                actionMenuOptions={actionMenuOptions}
                description={entry.description}
                group={entry.group}
                custom={entry.groupCustom}
                hasActionMenu={hasActionMenu}
                hasDescription={hasDescription}
                hasFavorite={hasFavorite}
                hasIcon={hasIcon}
                initials={entry.initials}
                isFavorite={entry.isFavorite}
                key={`${customKey} ${entry.name}`}
                name={entry.name}
                setFavorite={entry.setFavorite}
                type={entry.type}
              />
            );
          })}
        </Entries>
      </div>
    );
  }
)`
  color: ${({ theme }) => theme.color.additional.dark.value} ${AppSection} {
    margin-bottom: 16px;
  }
  container-type: inline-size;
  container-name: entries;
`;

export const EntryGroupWithDomains = styled(
  ({
    className,
    entries,
    app,
    actionMenuOptions,
    hasActionMenu = false,
    hasDescription = true,
    hasFavorite = true,
    hasIcon = true,
  }) => {
    const { currentUser, setFavorite, isFavorite, removeFavorite } = useUser(
      (state) => ({
        currentUser: state.currentUser,
        setFavorite: state.addFavoriteWorkflowByUser,
        isFavorite: state.isFavoriteWorkflow,
        removeFavorite: state.removeFavoriteWorkflowByUser,
      })
    );

    const hasDomains = entries.domains ? true : false;

    if (hasDomains) {
      return (
        <div className={className}>
          <AppSection
            group={app.group}
            custom={app.groupCustom}
            initials={app.initials}
            name={app.name}
          />
          {Object.entries(entries.domains).map(([domain, domainEntries]) => {
            const listForDomain = makeEntriesList({
              list: domainEntries,
              isFavorite,
              setFavorite,
              removeFavorite,
              user: currentUser,
            });
            return (
              <React.Fragment key={JSON.stringify(domain)}>
                <Domain>{domain}</Domain>
                <Entries>
                  {listForDomain.map((entry) => (
                    <EntryCard
                      actionMenuOptions={actionMenuOptions}
                      description={entry.description}
                      group={entry.group}
                      custom={entry.groupCustom}
                      hasActionMenu={hasActionMenu}
                      hasDescription={hasDescription}
                      hasFavorite={hasFavorite}
                      hasIcon={hasIcon}
                      initials={entry.initials}
                      isFavorite={entry.isFavorite}
                      key={entry.name}
                      name={entry.name}
                      setFavorite={entry.setFavorite}
                      type={entry.type}
                    />
                  ))}
                </Entries>
              </React.Fragment>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={className}>
          <AppSection
            group={app.group}
            custom={app.groupCustom}
            initials={app.initials}
            name={app.name}
          />

          <Entries>
            {entries
              .sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
              })
              .map((entry) => (
                <EntryCard
                  actionMenuOptions={actionMenuOptions}
                  description={entry.description}
                  group={entry.group}
                  custom={entry.groupCustom}
                  hasActionMenu={hasActionMenu}
                  hasDescription={hasDescription}
                  hasFavorite={hasFavorite}
                  hasIcon={hasIcon}
                  initials={entry.initials}
                  isFavorite={entry.isFavorite}
                  key={entry.name}
                  name={entry.name}
                  setFavorite={entry.setFavorite}
                  type={entry.type}
                />
              ))}
          </Entries>
        </div>
      );
    }
  }
)`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.gray[200].value};
  border-radius: 4px;
  ${AppSection} + ${Domain} {
    margin-top: 0;
  }
  container-type: inline-size;
  container-name: entries;
`;

export const StyledEntryGroup = styled((props) => {
  console.log("SHOULD NOT BE CALLED");
  if (props.entries.domains) return <EntryGroupWithDomains {...props} />;
  else return <SimpleEntryGroup {...props} />;
})``;

export const EntryGroup = (props) => <StyledEntryGroup {...props} />;
