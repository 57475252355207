import { create } from "zustand";
import { persist } from "zustand/middleware";
import profilePic1 from "../pngs/bob.smith.jpeg";

export const useUser = create(
  persist(
    (set, get) => ({
      currentUser: 0,
      users: {
        1: {
          name: "Bob Smith",
          role: "Accounting",
          employeeId: "123456",
          storeId: "12345678",
          storeName: "Tex Earnhardt Ford",
          profileImage: profilePic1,
          email: "bob@email.com",
          phone: "+1 (555) 111-2222",
          availableApps: [],
          availableWorkflows: [],
          favoriteApps: ["Fortellis", "CDK Drive", "CDK Elead"],
          favoriteWorkflows: [
            "Marketplace",
            "Subscription Portal",
            "Daily Organizer",
            "Search",
            "RO Dashboard",
          ],
          openWorkflowSections: ["CDK Elead"],
        },
      },

      setCurrentUser: (id) => set((state) => ({ currentUser: id })),

      toggleFavoriteApp: (id) =>
        set((state) => {
          const [favoriteApps, users] = state.getFavorite("apps");
          if (!favoriteApps.includes(id)) {
            favoriteApps.push(id);
          } else {
            users[get().currentUser].favoriteApps = favoriteApps.filter(
              (item) => item !== id
            );
          }
          return { users: { ...users } };
        }),
      toggleFavoriteWorkflow: (id) =>
        set((state) => {
          const [favoriteWorkflows, users] = state.getFavorite("workflows");
          console.log("Toggle", users, favoriteWorkflows, id);
          if (!favoriteWorkflows.includes(id)) {
            favoriteWorkflows.push(id);
          } else {
            users[get().currentUser].favoriteWorkflows =
              favoriteWorkflows.filter((item) => item !== id);
          }
          return { users: { ...users } };
        }),

      addFavoriteApp: (id) =>
        set((state) => {
          const [favoriteApps, users] = state.getFavorite("apps");
          if (!favoriteApps.includes(id)) favoriteApps.push(id);

          return { users: { ...users } };
        }),

      addFavoriteAppByUser: (userId, appId) =>
        set((state) => {
          const [favoriteApps, users] = state.getFavorite("apps");
          if (!favoriteApps.includes(appId)) favoriteApps.push(appId);

          return { users: { ...users } };
        }),

      addFavoriteWorkflowByUser: (userId, flowId) =>
        set((state) => {
          const [favoriteWorkflows, users] = state.getFavorite("workflows");
          if (!favoriteWorkflows.includes(flowId))
            favoriteWorkflows.push(flowId);

          return { users: { ...users } };
        }),

      addOpenWorkflowSection: (sectionId) =>
        set((state) => {
          const [users, user, openSections] = state.getOpenApps();
          if (!openSections.includes(sectionId)) openSections.push(sectionId);
          else {
            user.openWorkflowSections = openSections.filter((item) => {
              return item !== sectionId;
            });
          }

          return { users: { ...users } };
        }),

      getFavorite: (type) => {
        const users = JSON.parse(JSON.stringify(get().users));
        const currentUser = users[get().currentUser];
        return type === "apps"
          ? [currentUser.favoriteApps, users]
          : [currentUser.favoriteWorkflows, users];
      },

      getOpenApps: () => {
        const users = JSON.parse(JSON.stringify(get().users));
        const current = users[get().currentUser];
        return [users, current, current.openWorkflowSections];
      },

      isFavoriteAppCurrentUser: (appId) => {
        const favoriteApps = get().users[get().currentUser].favoriteApps;

        return favoriteApps.includes(appId);
      },

      isFavoriteApp: (userId, appId) => {
        const favoriteApps = get().users[userId].favoriteApps;
        return favoriteApps.includes(appId);
      },

      isFavoriteWorkflow: (userId, flowId) => {
        const favoriteWorkflows = get().users[userId].favoriteWorkflows;
        return favoriteWorkflows.includes(flowId);
      },

      isFavoriteWorkflowCurrentUser: (flowId) => {
        const favoriteWorkflows =
          get().users[get().currentUser].favoriteWorkflows;
        return favoriteWorkflows.includes(flowId);
      },

      isOpenWorkflowSection: (sectionId) => {
        const openSections =
          get().users[get().currentUser].openWorkflowSections;

        return openSections.includes(sectionId);
      },

      removeFavoriteApp: (id) =>
        set((state) => {
          const [favoriteApps, users] = state.getFavorite("apps");

          if (favoriteApps.includes(id))
            users[get().currentUser].favoriteApps = favoriteApps.filter(
              (item) => item !== id
            );
          return { users: { ...users } };
        }),

      removeFavoriteAppByUser: (userId, appId) =>
        set((state) => {
          const [favoriteApps, users] = state.getFavorite("apps");

          if (favoriteApps.includes(appId))
            users[userId].favoriteApps = favoriteApps.filter((item) => {
              return item !== appId;
            });
          return { users: { ...users } };
        }),

      removeFavoriteWorkflowByUser: (userId, flowId) =>
        set((state) => {
          const [favoriteWorkflows, users] = state.getFavorite("workflows");

          if (favoriteWorkflows.includes(flowId))
            users[userId].favoriteWorkflows = favoriteWorkflows.filter(
              (item) => {
                return item !== flowId;
              }
            );
          return { users: { ...users } };
        }),

      removeOpenWorkflowSection: (sectionId) =>
        set((state) => {
          const [users, user, openSections] = state.getOpenApps();

          if (openSections.includes(sectionId))
            user.openWorkflowSections = openSections.filter((item) => {
              return item !== sectionId;
            });
          return { users: { ...users } };
        }),
    }),
    {
      name: "bcv2-users",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["openWorkflowSections"].includes(key)
          )
        ),
    }
  )
);

export const useIsTrue = (store, fn) => {
  return store((state) => fn(state));
};
