import { entries } from "store/entries";

export const getAllWorkflows = () => {
  const list = {};
  for (const entry of Array.from(entries.values())) {
    if (entry.type === "workflow") {
      if (entry.domain) {
        if (list[entry.app]) {
          if (list[entry.app].domains) {
            if (list[entry.app].domains[entry.domain]) {
              list[entry.app].domains[entry.domain] = [
                ...list[entry.app].domains[entry.domain],
                entry,
              ];
            } else {
              list[entry.app].domains[entry.domain] = [entry];
            }
          } else {
            list[entry.app].domains = {};
            list[entry.app].domains[entry.domain] = [entry];
          }
        } else {
          list[entry.app] = {};
          list[entry.app].domains = {};
          list[entry.app].domains[entry.domain] = [entry];
        }
      } else {
        if (list[entry.app]) {
          list[entry.app] = [...list[entry.app], entry];
        } else {
          list[entry.app] = {};
          list[entry.app] = [entry];
        }
      }
    }
  }
  return list;
};
