import styled from "styled-components";
import { SimpleEntryGroup } from "./EntryGroup/EntryGroup";
import { Entries } from "./EntryGroup/Entries";
import { useUser } from "../store/useUser";
import { makeEntriesList } from "../utils/makeEntriesList";
import { IconFavorite } from "cdk-radial";

export const StyledAllApplications = styled(({ className, list }) => {
  const {
    currentUser,
    setFavorite,
    isFavorite,
    removeFavorite,
    toggleFavorite,
  } = useUser((state) => ({
    currentUser: state.currentUser,
    setFavorite: state.addFavoriteAppByUser,
    isFavorite: state.isFavoriteAppCurrentUser,
    removeFavorite: state.removeFavoriteAppByUser,
    toggleFavorite: state.toggleFavoriteApp,
  }));
  const appsEntries = makeEntriesList({
    list,
    isFavorite,
    setFavorite,
    removeFavorite,
    user: currentUser,
    toggleFavorite,
  });

  const actionMenuOptions = [
    {
      label: (appId) =>
        isFavorite(appId) ? "Remove from MyUnify" : "Add to MyUnify",
      value: "toggle",
      icon: <IconFavorite />,
      onClick: (appId) => toggleFavorite(appId),
    },
  ];
  return (
    <SimpleEntryGroup
      customKey="All Applications"
      className={className}
      title="All Applications"
      entries={appsEntries}
      hasActionMenu={true}
      actionMenuOptions={actionMenuOptions}
      hasDescription={false}
      hasFavorite={false}
    />
  );
})`
  padding: 0;
  ${Entries} {
    margin-top: 16px;
  }
`;

export const AllApplications = (props) => <StyledAllApplications {...props} />;
export default AllApplications;
