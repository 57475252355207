import { findEntry } from "./findEntry";

export const findAppEntry = (name) => {
  const app = findEntry({ name, type: "app" });
  if (app) return app;

  const workflow = findEntry({ name, type: "workflow" });
  if (workflow) {
    const app = findEntry({ name: workflow.app, type: "app" });
    return app;
  }
  return undefined;
};
