import styled from "styled-components";
import { SidebarHeader } from "./SidebarHeader";
import { useSidebar } from "store/useSidebar";
import { useOpenApps } from "store/useOpenApps";
import { EntryCard, StyledEntryCard } from "./EntryCard/EntryCard";

export const StyledOpenApps = styled(({ className }) => {
  const openApps = useOpenApps((state) => state.openApps);
  const closeSidebar = useSidebar((state) => state.closeSidebar);
  return (
    <div className={className}>
      <SidebarHeader
        title={`Open Applications & Workflows ${
          openApps.length > 0 ? `(${openApps.length})` : ""
        }`}
      />
      <section>
        {openApps.map((item, index) => (
          <EntryCard
            key={JSON.stringify(item)}
            onClick={closeSidebar}
            name={item.name}
            group={item.group}
            index={index}
            initials={item.initials}
            hasActionMenu={false}
            hasClose={true}
            hasDescription={false}
            hasFavorite={false}
            hasIcon={true}
          />
        ))}
      </section>
    </div>
  );
})`
  section {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    gap: 8px;
    padding: 16px;
  }
  #sidewidth {
    width: 120px;
  }
  ${StyledEntryCard} {
    border: 1px solid ${({ theme }) => theme.color.gray[200].value};
    background: ${({ theme }) => theme.color.gray[50].value};
  }
`;

export const OpenApps = (props) => <StyledOpenApps {...props} />;
