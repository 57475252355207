import styled from "styled-components";

export const Text = styled.div`
  font-weight: ${({ weight }) => weight ?? "400"};
  color: ${({ theme }) => theme.color.additional.dark.value};
  ${({ medium }) => (medium ? "&&& { font-weight: 500; }" : "")}
  ${({ bold }) => (bold ? "&&& { font-weight: 700; }" : "")}
  font-family: ${({ family }) => family ?? "Montserrat"};
  letter-spacing: 0;
`;

Text.Display1 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.display1.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.display1.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.display1.lineHeight.value};
`;
Text.Display2 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.display2.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.display2.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.display2.lineHeight.value};
`;
Text.Display3 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.display3.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.display3.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.display3.lineHeight.value};
`;
Text.Heading1 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.heading1.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.heading1.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.heading1.lineHeight.value};
`;
Text.Heading2 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.heading2.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.heading2.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.heading2.lineHeight.value};
`;
Text.Heading3 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.heading3.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.heading3.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.heading3.lineHeight.value};
`;
Text.Heading4 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.heading4.fontSize.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.heading4.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.heading4.lineHeight.value};
`;
Text.Heading5 = styled(Text)`
  font-size: 16px;
  letter-spacing: ${({ theme }) =>
    theme.typography.heading5.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.heading5.lineHeight.value};
`;
Text.Heading6 = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 20px;
`;
Text.Body1 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.body1.fontSize.value};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight.value};
  letter-spacing: ${({ theme }) => theme.typography.body1.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight.value};
`;
Text.Body2 = styled(Text)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize.value};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight.value};
  letter-spacing: ${({ theme }) => theme.typography.body2.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight.value};
`;
Text.Caption = styled(Text)`
  font-size: ${({ theme }) => theme.typography.caption.fontSize.value};
  font-weight: ${({ theme }) => theme.typography.caption.fontWeight.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.caption.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.caption.lineHeight.value};
`;
Text.CaptionSmall = styled(Text)`
  font-size: ${({ theme }) => theme.typography.captionSmall.fontSize.value};
  font-weight: ${({ theme }) => theme.typography.captionSmall.fontWeight.value};
  letter-spacing: ${({ theme }) =>
    theme.typography.captionSmall.letterSpacing.value};
  line-height: ${({ theme }) => theme.typography.captionSmall.lineHeight.value};
`;
Text.LinkDesktop = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;
Text.LinkMobile = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
