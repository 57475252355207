export const makeEntriesList = ({
  list,
  isFavorite,
  setFavorite,
  removeFavorite,
  user,
}) =>
  list.map((entry) => {
    return {
      ...entry,
      isFavorite: isFavorite(user, entry.name),
      openContent: () => {},
      setFavorite: () => {
        if (isFavorite(user, entry.name)) {
          removeFavorite(user, entry.name);
        } else {
          setFavorite(user, entry.name);
        }
      },
    };
  });
