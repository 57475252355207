import styled from "styled-components";
import { IconButton, IconClose } from "cdk-radial";

export const CloseButton = styled(IconButton).attrs({
  text: "close",
  icon: <IconClose />,
})`
  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.color.additional.dark.value};
  }
`;
