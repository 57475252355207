// import { useState } from "react";
import styled from "styled-components";
import { Text } from "./Text";
import { SidebarHeader } from "./SidebarHeader";
// import { useSidebar } from "../store/useSidebar";
import { useSettings } from "../store/useSettings";
import { useUser } from "../store/useUser";
import {
  SecondaryButton,
  Switch,
  SWITCH_LABEL_POSITIONS,
  // Input,
} from "cdk-radial";

const Label = styled(Text.Body1)``;

const Footer = styled.div`
  margin-left: 16px;
  position: absolute;
  bottom: 16px;
`;

export const StyledSettings = styled(({ className }) => {
  const { isDarkMode, setIsDarkMode } = useSettings((state) => ({
    isDarkMode: state.isDarkMode,
    setIsDarkMode: state.setIsDarkMode,
  }));
  // const { width, setWidth } = useSidebar((state) => ({
  //   width: state.width,
  //   setWidth: state.setWidth,
  // }));
  // const [internalWidth, setInternalWidth] = useState(width);
  const resetLocalStorage = () => {
    useUser.persist.clearStorage();
    useSettings.persist.clearStorage();
    window.location.reload();
  };
  return (
    <div className={className}>
      <SidebarHeader title="Settings" />
      <section>
        <Label>Dark Mode</Label>
        <Switch
          checked={isDarkMode}
          label="Dark mode"
          labelPosition={SWITCH_LABEL_POSITIONS.BEFORE}
          name="dark-mode"
          hideLabel={true}
          onChange={() => setIsDarkMode(!isDarkMode)}
        />
      </section>
      {/* <section id="sidewidth">
        <Input
          name="width"
          id="width"
          label="Sidebar Width"
          value={internalWidth}
          onChange={(e) => setInternalWidth(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter" ? setWidth(internalWidth) : null
          }
          onBlur={() => setWidth(internalWidth)}
        />
      </section> */}
      <Footer>
        <SecondaryButton text="reset cache" onClick={resetLocalStorage} />
      </Footer>
    </div>
  );
})`
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  #sidewidth {
    width: 120px;
  }
`;

export const Settings = (props) => <StyledSettings {...props} />;
