import styled from "styled-components";
import { Text } from "./Text";
import { IconClose, BUTTON_SIZES } from "cdk-radial";
import { useSidebar } from "../store/useSidebar";
import { ReverseIconButton } from "./ReverseIconButton";

const CloseButton = styled(ReverseIconButton).attrs({
  icon: <IconClose />,
  size: BUTTON_SIZES.SMALL,
  text: "close",
})`
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledSidebarHeader = styled(({ className, title }) => {
  const closeSidebar = useSidebar((state) => state.closeSidebar);

  return (
    <header className={className}>
      <Text.Body2 bold>{title}</Text.Body2>
      <CloseButton onClick={closeSidebar} />
    </header>
  );
})`
  background: black;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Text} {
    color: white;
  }

  &&& svg {
    fill: white;
  }
`;

export const SidebarHeader = (props) => <StyledSidebarHeader {...props} />;
