import { useState } from "react";
import styled from "styled-components";
import {
  StyledEntryGroup,
  EntryGroupWithDomains,
} from "./EntryGroup/EntryGroup";
import { findEntry } from "../utils/findEntry";
import { Text } from "./Text";
import { Checkbox } from "cdk-radial";

import { useModern } from "store/useModern";

const Suite = styled(Text.Heading3)`
  font-weight: 600;
  margin-bottom: 0px;
`;
const SuiteDescription = styled(Text.Heading4)`
  margin-bottom: 8px;
  font-weight: 400;
`;
const Entries = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  border: 2px solid ${({ theme }) => theme.color.gray[400].value};

  padding: 8px;
  margin-bottom: 32px;
`;
const ProductAnalytics = styled(Text.Body1)`
  background: blue;
  color: white;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.4;
  padding: 0 1px;
`;
const DesignSystems = styled(Text.Body1)`
  font-size: 10px;
  font-weight: 800;
`;
const System = styled.span`
  margin-right: 8px;
`;
const Rds6 = styled(System)`
  color: ${({ theme }) => theme.color.secondary.cookieMonsterBlue[600].value};
`;
const Css = styled(System)`
  color: ${({ theme }) => theme.color.secondary.cherryBombRed[600].value};
`;
const Tbd = styled(System)`
  color: ${({ theme }) => theme.color.secondary.grapeApePurple[600].value};
`;
const Roadster = styled(System)`
  color: ${({ theme }) => theme.color.additional.dark.value};
`;

const Entry = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray[400].value};
  padding: 8px;
  text-align: center;
  min-height: 40px;
  background: ${({ theme, item, usingFancy }) => {
    if (!usingFancy) return "transparent";
    if (item.group === "Drive Features") return theme.color.gray[200].value;
    if (item.group === "Modern Retail")
      return theme.color.secondary.kermitGreen[200].value;
    if (item.group === "Fixed Ops")
      return theme.color.secondary.cookieMonsterBlue[200].value;
    if (item.group === "Intelligence")
      return theme.color.secondary.grapeApePurple[200].value;
    if (item.group === "Not in Drive") return theme.color.gray[400].value;
  }};
  position: relative;
  ${ProductAnalytics} {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
  }
  ${DesignSystems} {
    position: absolute;
    left: 5px;
    bottom: 0px;
  }
`;

const Name = styled(Text.Heading4)`
  line-height: 1.1;
  margin-bottom: 4px;
  font-weight: 500;
`;
const Subtitle = styled(Text.Caption)``;

const SuitesWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const Suites = styled.div`
  flex: 1 1 0;
`;
const Key = styled.div`
  flex: 0 1 0;
  font-size: 12px;
  margin: 0;
  color: ${({ theme }) => theme.color.additional.dark.value};
  ${Entry} {
    min-height: 0;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${ProductAnalytics} {
    display: inline;
  }
`;
const KeyHeader = styled(Text.Display3)``;

export const StyledNewOrgAlt = styled(({ className }) => {
  const [usingFancy, setUsingFancy] = useState(false);
  const suites = useModern((state) => state.suites);
  return (
    <div className={`NewOrg ${className}`}>
      <Checkbox
        name="fancy"
        label="Show details"
        checked={usingFancy}
        onChange={(e) => setUsingFancy(e.target.checked)}
      />
      <SuitesWrapper>
        <Suites>
          {Object.entries(suites).map(([suite, data]) => (
            <div key={JSON.stringify(suite)}>
              <Suite>{suite}</Suite>
              <SuiteDescription>{data.desc}</SuiteDescription>
              <Entries>
                {data.list.map((item) => (
                  <Entry
                    item={item}
                    key={JSON.stringify(item)}
                    usingFancy={usingFancy}
                  >
                    <Name>{item.name}</Name>
                    {item.subtitle && <Subtitle>({item.subtitle})</Subtitle>}
                    {usingFancy && (
                      <DesignSystems>
                        {item.designSystem.map((ds) => {
                          switch (ds) {
                            case "RDS 6":
                            case "RDS 6 in Store":
                              return (
                                <Rds6 key={`${JSON.stringify(item)}-${ds}`}>
                                  {ds}
                                </Rds6>
                              );
                            case "TBD":
                              return (
                                <Tbd key={`${JSON.stringify(item)}-${ds}`}>
                                  TBD
                                </Tbd>
                              );
                            case "CSS/Flex":
                            case "CSS (COSA)":
                            case "CSS":
                              return (
                                <Css key={`${JSON.stringify(item)}-${ds}`}>
                                  {ds}
                                </Css>
                              );
                            case "Roadster":
                              return (
                                <Roadster key={`${JSON.stringify(item)}-${ds}`}>
                                  Roadster
                                </Roadster>
                              );

                            default:
                              <span>{ds}</span>;
                          }
                        })}
                      </DesignSystems>
                    )}
                    {usingFancy && item.hasProductAnalytics && (
                      <ProductAnalytics>A</ProductAnalytics>
                    )}
                  </Entry>
                ))}
              </Entries>
            </div>
          ))}
        </Suites>
        {usingFancy && (
          <Key>
            <KeyHeader>Key</KeyHeader>
            <Entry item={{ group: "Drive Features" }} usingFancy={true}>
              Drive Features
            </Entry>
            <Entry item={{ group: "Not in Drive" }} usingFancy={true}>
              Not in Drive
            </Entry>
            <Entry item={{ group: "Fixed Ops" }} usingFancy={true}>
              Fixed Ops
            </Entry>
            <Entry item={{ group: "Modern Retail" }} usingFancy={true}>
              Modern Retail
            </Entry>
            <Entry item={{ group: "Intelligence" }} usingFancy={true}>
              Intelligence
            </Entry>
            <div>
              <ProductAnalytics>A</ProductAnalytics> Product Analytics
            </div>
          </Key>
        )}
      </SuitesWrapper>
    </div>
  );
})``;

export const NewOrgAlt = (props) => <StyledNewOrgAlt {...props} />;
