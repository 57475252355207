import styled, { keyframes } from "styled-components";
import {
  IconButton,
  IconApps,
  IconSettings,
  IconHelp,
  IconAccountCircle,
  // IconNotification,
} from "cdk-radial";
import { useSheet } from "../store/useSheet";
import { ReactComponent as CDKLogo } from "../svgs/cdk_logo.svg";
import { Profile } from "./Profile";
import { Settings } from "./Settings";
import { OpenApps } from "./OpenApps";

const Logo = styled.div`
  height: 40px;
  width: 150px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ReverseIconButton = styled(IconButton)`
  &&& > svg {
    fill: #fff;
  }
`;

const OpenAppsButton = styled(ReverseIconButton).attrs({
  icon: <IconApps />,
  text: "Apps",
})``;
const HelpButton = styled(ReverseIconButton).attrs({
  icon: <IconHelp />,
  text: "Help",
})``;
const SettingsButton = styled(ReverseIconButton).attrs({
  icon: <IconSettings />,
  text: "Settings",
})``;
const UserButton = styled(ReverseIconButton).attrs({
  icon: <IconAccountCircle />,
  text: "Account",
})``;
// const NotificationButton = styled(ReverseIconButton).attrs({
//   icon: <IconNotification />,
//   text: "Notifications",
// })``;

const HelpContent = styled(({ className }) => {
  return (
    <div className={className}>
      {" "}
      <iframe
        title="Chat"
        border="0"
        src="https://app-backend-4jkiyhgce2qdu.azurewebsites.net/"
      ></iframe>
    </div>
  );
})`
  background: white;
  iframe {
    width: 100%;
    height: calc(100vh - 50px);
    border-radius: 8px;
    background: white;
  }
`;

const bounce = keyframes`
20% {
background: gold;
border-radius: 50%;
transform: scale(.6);
}
30% {
  transform:scale(1);
background: gold;

}
80% {
  background: #0a0800;

}
100% {

}
`;

// const addGlow = keyframes`
// 20% {
//   background: gold;
//   border-radius: 50%;
//   transform: scale(1.1);
// }
// 80% {
//   background: gold;
//   border-radius: 50%;
//   transform: scale(1.1);
//   }
// `;
const addColor = keyframes`
20% { fill:black; } 

`;

export const GlobalHeader = styled(({ className, sidebarHandler }) => {
  const setContent = useSheet((state) => state.setContent);
  const setLoadingState = useSheet((state) => state.setLoadingState);

  return (
    <div className={className}>
      <Logo
        onClick={() => {
          setContent(null);
          setLoadingState("empty");
          const openApps = document.querySelector("#openApps");
          const svg = openApps.querySelector("svg");
          openApps.classList.add("addGlow");
          svg.classList.add("addColor");

          setTimeout(() => {
            openApps.classList.remove("addGlow");
            svg.classList.remove("addColor");
          }, 2000);
        }}
      >
        <CDKLogo />
      </Logo>
      <OpenAppsButton
        id="openApps"
        onClick={() =>
          sidebarHandler({
            component: <OpenApps />,
            name: "Apps",
          })
        }
      />
      <HelpButton
        onClick={() =>
          sidebarHandler({
            component: <HelpContent />,
            name: "Help",
            width: 1000,
          })
        }
      />
      <SettingsButton
        onClick={() =>
          sidebarHandler({
            component: <Settings />,
            name: "Settings",
          })
        }
      />
      <UserButton
        onClick={() =>
          sidebarHandler({ component: <Profile />, name: "Profile" })
        }
      />
      {/* <NotificationButton onClick={() => setIsCollapsed(!isCollapsed)} /> */}
    </div>
  );
})`
  display: flex;
  align-items: center;
  background: black;
  ${OpenAppsButton} {
    margin-left: auto;
  }
  gap: 8px;
  padding-right: 8px;
  .addGlow {
    animation: ${bounce} 0.75s;
    animation-fill-mode: forwards;
  }
  .addColor {
    animation: ${addColor} 0.75s;
    animation-fill-mode: forwards;
  }
`;
