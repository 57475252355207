import styled from "styled-components";
import { Text } from "../Text";

export const Headline = styled(Text.Body1).attrs({
  className: "CarouselHeadline",
})`
  padding: 12px 24px 8px 24px;
  color: ${({ theme }) => theme.color.gray[600].value};
  text-align: left;
`;
