import styled from "styled-components";
import { Banner } from "./Banner";
import { StyledUnifyTabsController, UnifyTabsController } from "./UnifyTabs";

import { Outlet } from "react-router-dom";

const Main = styled.section`
  background: white;
`;

const Pane = styled.div`
  display: none;
  &.show {
    display: block;
  }
`;
export const MyUnify = styled(Pane)``;
export const Applications = styled(Pane)``;
export const Workflows = styled(Pane)``;

export const Backplane = styled(
  ({ bannerOpen, setBannerOpen, className, favoriteApps, favoriteFlows }) => {
    return (
      <div className={className} id="backplane">
        <Banner bannerOpen={bannerOpen} setBannerOpen={setBannerOpen} />
        <Main>
          <UnifyTabsController />
          <Outlet />
        </Main>
      </div>
    );
  }
)`
  p {
    margin: 0;
  }

  ${Main} {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 16px 16px;
    background: ${({ theme }) => theme.color.additional.light.value};
  }
  box-sizing: border-box;
  ${StyledUnifyTabsController} {
    margin: 16px 0;
  }
`;
