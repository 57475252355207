import { useRef, useState } from "react";
import styled from "styled-components";
import { IconButton, IconMoreVert, Menu } from "cdk-radial";
import { CustomMenu } from "./CustomMenu";
import { CustomMenuListItem } from "./CustomMenuListItem";

export const StyledEntryCardActionMenu = styled(
  ({ className, options, appId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const targetRef = useRef();
    const handleOpen = (event) => {
      setIsOpen((isOpen) => !isOpen);
    };

    const handleClick = (event, action) => {
      action();
    };

    return (
      <div className={className}>
        <IconButton
          ref={targetRef}
          icon={<IconMoreVert />}
          text="Actions"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <CustomMenu
          labelRef={targetRef}
          style={{ width: "fit-content" }}
          isAuto={true}
          onOpen={handleOpen}
          onClose={handleOpen}
        >
          {options.map((option, ind) => {
            return (
              <CustomMenuListItem
                key={`ActionMenu index ${ind}`}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, () => option.onClick(appId));
                }}
                hideSelectedItemCheckmark={false}
                graphicContent={option.icon}
              >
                {typeof option.label === "function"
                  ? option.label(appId)
                  : option.label}
              </CustomMenuListItem>
            );
          })}
        </CustomMenu>
      </div>
    );
  }
)`
  margin-right: -16px;
`;

export const EntryCardActionMenu = (props) => (
  <StyledEntryCardActionMenu {...props} />
);
