import { useState } from "react";
import styled from "styled-components";
import { IconButton, IconExpandLess, IconExpandMore } from "cdk-radial";

const Toggle = styled.div`
  padding: 8px;
  display: flex;
  align-content: center;
  svg {
    fill: ${({ theme }) => theme.color.additional.dark.value};
  }
`;
const Content = styled.div`
  flex-grow: 1;
`;

export const StyledAccordion = styled(
  ({
    contentId,
    isAccordionOpen,
    className,
    setIsAccordionOpen,
    contentOpen,
    contentClosed,
  }) => {
    const [isInternalOpen, setIsInternalOpen] = useState(false);
    const isOpen = isAccordionOpen || isInternalOpen;
    const setIsOpen = () => setIsAccordionOpen(contentId) || setIsInternalOpen;

    return (
      <div
        className={`Accordion ${className}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Content>{isOpen ? contentOpen : contentClosed}</Content>
        <Toggle>
          {isOpen ? (
            <IconButton icon={<IconExpandLess />} text="" />
          ) : (
            <IconButton icon={<IconExpandMore />} text="" />
          )}
        </Toggle>
      </div>
    );
  }
)`
  border: 1px solid ${({ theme }) => theme.color.gray[200].value};

  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  ${Toggle} {
    margin-top: 16px;
    margin-left: auto;
  }
`;

export const Accordion = (props) => <StyledAccordion {...props} />;
