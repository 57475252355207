import { useEffect, useRef } from "react";
import { useSheet } from "../store/useSheet";
import styled, { keyframes } from "styled-components";

const Inside = styled.div`
  position: relative;
  display: block;
  &.loaded {
    display: block;
  }
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const Cover = styled.div`
  &.empty {
    display: none;
  }
  &.loading {
    display: flex;
  }
  &.loaded {
    display: none;
  }
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
`;

// const zoom = keyframes`
//    0% {
//       width: 100%;
//       height: calc(100% - 100px);
//       top: 100px;
//       right: 0;
//    }
//    10% {
//       background: grey;
//       width: 100%;
//       height: calc(100% - 100px);
//       top: 100px;
//       right: 0;
//    }
//       20% {
//       background: grey;
//       width: 100%;
//       height: calc(100% - 100px);
//       top: 100px;
//       right: 0;
//    }
//    40% {
//       width: 50px;
//       height: 50px;
//       top: 0;
//       right: 0;
//       opacity: 1;
//       background: grey;
//    }
//    60% {
//       width: 50px;
//       height: 50px;
//       top: 0;
//       right: 0;
//       opacity: 1;
//       background: grey;
//    }

//    70% {
//       width: 50px;
//       height: 50px;
//       top: 0;
//       right: 0;
//       opacity: 1;
//       background: grey;
//    }
//    100% {
//       width: 50px;
//       height: 50px;
//       top: 40px;
//       right: 0;
//       opacity: 0;
//       background: grey;
//    }
// `;

const openZoom = keyframes`
  0% {
    left:0;
    right:0;
    height: 0%;
    bottom:0;
    top:40px;
    opacity:0;
  }
  100% {
    left:0;
    right:0;
    bottom:0;
    top:40px;
    opacity:1;
  }
`;

export const StyledSheet = styled(({ className }) => {
  const sheetContent = useSheet((state) => state.content);
  const loadingState = useSheet((state) => state.loadingState);
  const setLoadingState = useSheet((state) => state.setLoadingState);

  const iframeRef = useRef();

  useEffect(() => {
    const log = () => {
      setLoadingState("loaded");
    };
    const current = iframeRef.current;
    current?.addEventListener("load", log);
    return () => {
      current?.removeEventListener("load", log);
      setLoadingState("empty");
    };
  }, [setLoadingState]);

  return (
    <div className={`${sheetContent ? "zoom" : ""} ${className}`}>
      <Inside>
        <Cover className={loadingState}>Loading...</Cover>
        <iframe
          title="Application"
          className={loadingState}
          ref={iframeRef}
          src={sheetContent}
        ></iframe>
      </Inside>
    </div>
  );
})`
  background: white;
  display: none;
  overflow: scroll;
  position: fixed;
  z-index: 10;
  .Inside {
    width: 100%;
    height: 100%;
  }

  &.zoom {
    animation-name: ${openZoom};
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0.01, 0, 1);
    display: block;
  }
  &.zoom .Cover {
    animation-name: ${openZoom};
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0.01, 0, 1);
  }
`;

export const Sheet = (props) => <StyledSheet {...props} />;
