import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useBanners } from "store/useBanners";
import { IconButton, IconExpandLess } from "cdk-radial";
import { Container } from "./Container";
import { Wrapper } from "./Wrapper";
import { Pane } from "./Pane";

const bannerWidth = "1280px";
const paneSlidingSpeed = "0.5s";
const rotationSpeed = 1000 * 15; //1000ms * 5 = 5s
const replacementSpeed = 600; // how quick slot1 is replaced
// const pauseAmount = 1000 * 10; //1000ms * 10 = 10s

const Collapse = styled(IconButton).attrs({
  icon: <IconExpandLess />,
  text: "",
  className: "Collapse",
})`
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 10;
`;

export const Carousel = styled(({ className, close }) => {
  const [containerClassName, setContainerClassName] = useState("");
  const [container, setContainer] = useState({ slot1: 0, slot2: 1 });
  const paneArray = useBanners();
  const tempClass = useRef("");

  const slot1 = paneArray[container.slot1];
  const slot2 = paneArray[container.slot2];

  const handleThumbnailClick = (index) => {
    setContainer({
      slot1: index,
      slot2: (index + 1) % paneArray.length,
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (tempClass.current !== "pause") {
        setContainerClassName("move");
        setTimeout(() => {
          setContainer((currentContainer) => ({
            slot1: (currentContainer.slot1 + 1) % paneArray.length,
            slot2: (currentContainer.slot2 + 1) % paneArray.length,
          }));
          setContainerClassName("");
        }, replacementSpeed);
      }
    }, rotationSpeed);
    return () => clearInterval(timer);
  }, [paneArray.length]);

  return (
    <div className={`Carousel ${className}`}>
      <>
        <Wrapper bannerWidth={bannerWidth}>
          <Collapse />
          <Container
            bannerWidth={bannerWidth}
            paneSlidingSpeed={paneSlidingSpeed}
            className={containerClassName}
          >
            <Pane
              pane={slot1}
              bannerWidth={bannerWidth}
              paneArray={paneArray}
              container={container}
              onThumbnailClick={handleThumbnailClick}
            />
            <Pane
              pane={slot2}
              bannerWidth={bannerWidth}
              paneArray={paneArray}
              container={container}
            />
          </Container>
        </Wrapper>
      </>
    </div>
  );
})`
  margin-top: 0;
  margin-bottom: 32px;
`;
