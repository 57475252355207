import styled from "styled-components";
import { getColorFromGroup } from "../utils/getColorFromGroup";
import { useSettings } from "store/useSettings";

const setCase = (initials) =>
  initials.case === "upper"
    ? initials.initials.toUpperCase()
    : initials.case === "initial"
    ? `${initials.initials.at(0).toUpperCase()}${initials.initials
        .slice(1)
        .toLowerCase()}`
    : initials.initials;

export const AppIcon = styled(({ className, initials, group, custom }) => (
  <div className={className}>
    <div>{setCase(initials)}</div>
  </div>
))`
  background: ${({ group }) => getColorFromGroup(group)};
  border: 1px solid ${({ group }) => getColorFromGroup(group)};
  ${({ custom }) => {
    const isDarkMode = useSettings((state) => state.isDarkMode);
    if (custom) {
      if (isDarkMode && custom.borderColorDarkMode) {
        return `border-color:${custom.borderColorDarkMode};`;
      }
    }
  }}
  border-radius: 2px;
  color: ${({ theme }) => theme.color.additional.alwaysWhite.value};
  flex-shrink: 0;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  height: 47px;
  letter-spacing: -0.06em;
  position: relative;
  text-align: right;
  width: 47px;

  div {
    position: absolute;
    inset: auto 4px 0 auto;
  }
`;
