import styled from "styled-components";
import { AppIcon } from "../AppIcon";
import { AppName } from "../AppName";
import { useSheet } from "../../store/useSheet";
import { useOpenApps } from "../../store/useOpenApps";
import { findAppEntry } from "../../utils/findAppEntry";
import {
  EntryCardActionMenu,
  StyledEntryCardActionMenu,
} from "../EntryCardActionMenu";
import { Card } from "cdk-radial";
import { CloseButton } from "./CloseButton";
import { Description } from "./Description";

export const StyledEntryCard = styled(
  ({
    actionMenuOptions,
    className,
    name,
    group,
    custom,
    index,
    initials,
    description,
    onClick,
    hasActionMenu = false,
    hasClose = false,
    hasDescription = true,
    hasIcon = true,
  }) => {
    const setContent = useSheet((state) => state.setContent);
    const addOpenApp = useOpenApps((state) => state.addOpenApp);
    const removeOpenApp = useOpenApps((state) => state.removeOpenApp);
    const handleClick = (name) => {
      const app = findAppEntry(name);
      setContent(name);
      addOpenApp(app);
      if (onClick) onClick(name);
    };
    const handleCloseClick = (event) => {
      event.stopPropagation();
      removeOpenApp(index);
    };
    return (
      <Card className={className} onClick={() => handleClick(name)}>
        <header>
          {hasIcon && (
            <AppIcon initials={initials} group={group} custom={custom} />
          )}
          <AppName>{name}</AppName>
          {hasActionMenu && (
            <EntryCardActionMenu options={actionMenuOptions} appId={name} />
          )}
          {hasClose && <CloseButton onClick={handleCloseClick} />}
        </header>
        {hasDescription && <Description>{description}</Description>}
      </Card>
    );
  }
)`
  background: ${({ theme }) => theme.color.gray[50].value};
  :hover {
    background: ${({ theme }) => theme.color.gray[100].value};
  }

  border: 1px solid ${({ theme }) => theme.color.gray[200].value};

  border-radius: 4px;
  box-shadow: none;
  color: ${({ theme }) => theme.color.additional.dark.value};
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  gap: 8px;
  justify-content: flex-start;
  line-height: 1.2;
  padding: ${({ hasIcon }) => (hasIcon ? "16px" : "4px 4px 16px 16px")};
  header + div {
    font-size: 14px;
    text-align: left;
  }
  position: relative;

  ${AppName} {
    ${({ hasIcon }) => {
      return hasIcon ? "margin-top: 0; margin-bottom:0;" : "";
    }}
  }

  header {
    align-items: center;
    display: flex;
    gap: 8px;
    ${({ hasActionMenu }) => (hasActionMenu ? "margin-right: 16px;" : "")}
    ${CloseButton} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &:hover {
    ${StyledEntryCardActionMenu} {
      display: block;
    }
    cursor: pointer;
  }
  ${StyledEntryCardActionMenu} {
    display: none;
    margin-left: auto;
  }
`;

export const EntryCard = (props) => <StyledEntryCard {...props} />;
