import React from "react";
import { menuGetStateStyles } from "./menuGetStateStyles";
import {
  focusOutlineColors,
  getRem,
  IconCheck,
  ListItemPrimaryText,
  ListItemText,
  ListItem,
  ListItemGraphic,
  ListItemCheckbox,
} from "cdk-radial";

import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const StyledIconCheck = styled(IconCheck)`
  ${({ theme }) => css`
    align-self: flex-start;
    fill: ${theme.color.primary[500].value};
    flex-shrink: 0;
    height: 100%;
    padding-left: ${theme.size.spacing.medium.value};
    width: ${getRem(18)};
  `}
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)`
  color: ${({ isDisabled, theme }) =>
    isDisabled && theme.color.text.light.disabled.value};
`;

const StyledListItemCheckbox = styled(ListItemCheckbox)`
  margin-left: ${getRem(2)};
  padding-right: 0;
`;

const StyledListItem = styled(ListItem)`
  overflow: visible;
  ${({ isSelected, showCheckbox }) => css`
    ${!showCheckbox && `padding-right: ${isSelected ? getRem(6) : getRem(38)}`};
    ${showCheckbox
      ? menuGetStateStyles(false).default
      : menuGetStateStyles(isSelected).default};
  `}

  ${({ isDisabled, isKeyboardFocused, isSelected, showCheckbox, theme }) =>
    !isDisabled &&
    css`
      cursor: pointer;
      &:hover {
        ${/* sc-selector */ ListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.hover.value
            : theme.color.state.default.hover.value};
        }
        ${!showCheckbox && menuGetStateStyles(isSelected).hover}
      }

      &:focus {
        ${/* sc-selector */ ListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.focus.value
            : theme.color.state.default.focus.value};
          ${isKeyboardFocused &&
          css`
            box-shadow: 0 0 0 4px
              ${isSelected
                ? focusOutlineColors.getPrimary(theme)
                : focusOutlineColors.getDark(theme)};
          `}
        }
        ${!showCheckbox &&
        isKeyboardFocused &&
        menuGetStateStyles(isSelected).focus}
        &:not(:hover) {
          ${/* sc-selector */ ListItemCheckbox}::after {
            ${!isKeyboardFocused &&
            css`
              background-color: transparent;
            `}
          }
        }
      }

      &:active {
        ${/* sc-selector */ ListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.pressed.value
            : theme.color.state.default.pressed.value};
          box-shadow: none;
        }
        ${!showCheckbox && menuGetStateStyles(isSelected).active}
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
    pointer-events: all;
`;

const CustomMenuListItem = React.forwardRef(
  (
    {
      children,
      className,
      customListItemTag,
      dataTestId,
      graphicContent,
      hideSelectedItemCheckmark,
      indeterminate,
      isDisabled,
      isKeyboardFocused,
      isSelected,
      onClick,
      onSelect,
      showCheckbox,
      showDivider,
      tabIndex,
    },
    ref
  ) => {
    const handleClick = (e) => {
      if (onClick) {
        onClick(e);
      }
      if (onSelect) {
        onSelect(ref);
      }
    };

    return (
      <StyledListItem
        ref={ref}
        tabIndex={tabIndex}
        isSelected={isSelected}
        isKeyboardFocused={isKeyboardFocused}
        isDisabled={isDisabled}
        customListItemTag={customListItemTag}
        className={className}
        showDivider={showDivider}
        showCheckbox={showCheckbox}
        hideStateVisibility={showCheckbox}
        data-testid={dataTestId}
        onClick={handleClick}
      >
        {showCheckbox && (
          <StyledListItemCheckbox
            data-testid={dataTestId ? `${dataTestId}-checkbox` : undefined}
            indeterminate={indeterminate}
            checked={isSelected}
            isDisabled={isDisabled}
            isKeyboardFocused={isKeyboardFocused}
          />
        )}
        {graphicContent && (
          <ListItemGraphic
            data-testid={dataTestId ? `${dataTestId}-graphic` : undefined}
            isDisabled={isDisabled}
          >
            {graphicContent}
          </ListItemGraphic>
        )}
        <ListItemText
          data-testid={dataTestId ? `${dataTestId}-text` : undefined}
        >
          <StyledListItemPrimaryText
            data-testid={dataTestId ? `${dataTestId}-primarytext` : undefined}
            isDisabled={isDisabled}
          >
            {children}
          </StyledListItemPrimaryText>
        </ListItemText>
        {isSelected && !showCheckbox && !hideSelectedItemCheckmark && (
          <StyledIconCheck />
        )}
      </StyledListItem>
    );
  }
);

CustomMenuListItem.propTypes = {
  /** Flag to show menu item as selected */
  isSelected: PropTypes.bool,
  /** Node/element to show inside menu item */
  children: PropTypes.node.isRequired,
  /** Adds new class to Menu */
  className: PropTypes.string,
  /** Callback function to trigger on click */
  onClick: PropTypes.func,
  /** Tab index attribute to enable keyboard focus */
  tabIndex: PropTypes.number,
  /** Flag to enable keyboard styles on menu items */
  isKeyboardFocused: PropTypes.bool,
  /** Disable filter to disable menu item */
  isDisabled: PropTypes.bool,
  /** Ability to supply a different element instead of the default one for ListItem element */
  customListItemTag: PropTypes.elementType,
  /** Accepts Avatar / Icon / Image inside menu item  */
  graphicContent: PropTypes.node,
  /** Enables Checkbox in menu item */
  showCheckbox: PropTypes.bool,
  /** Enables divider between menu items */
  showDivider: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Changes the icon and sets checkbox indeterminate parameter state */
  indeterminate: PropTypes.bool,
  /** callback function which triggers when selected is passed */
  onSelect: PropTypes.func,
  /** Hide selected Icon */
  hideSelectedItemCheckmark: PropTypes.bool,
};

CustomMenuListItem.defaultProps = {
  isSelected: false,
  className: "",
  onClick: undefined,
  tabIndex: 0,
  isKeyboardFocused: false,
  customListItemTag: undefined,
  graphicContent: undefined,
  showCheckbox: false,
  showDivider: false,
  dataTestId: "",
  indeterminate: false,
  onSelect: undefined,
  hideSelectedItemCheckmark: false,
};

export { CustomMenuListItem };
