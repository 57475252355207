import styled from "styled-components";
import { EntryCard } from "components/EntryCard/EntryCard";

export const AppSection = styled(EntryCard).attrs({
  description: false,
  hasActionMenu: false,
  hasIcon: true,
  hasFavorite: false,
  type: "app",
})`
  &&&& {
    background: transparent;
    padding: 0;
    border: 0;
    gap: 16px;
  }
`;
