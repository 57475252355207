import styled from "styled-components";
import { useWindowWidth } from "../../utils/useBrowserWidth";

export const Wrapper = styled.div.attrs({ className: "CarouselWrapper" })`
  position: relative;
  width: ${() => {
    const windowWidth = useWindowWidth();
    return windowWidth > 1280 ? "1280px" : `${windowWidth - 32}px`;
  }};
  overflow-y: auto;
  overflow-x: hidden;
  height: 122px;
  margin: 0 auto 10px auto;
  text-align: right;
`;
