import styled from "styled-components";
import { useSettings } from "store/useSettings";

export const Logo = styled.div.attrs({ className: "CarouselLogo" })`
  width: 356px;
  height: 122px;
  background: ${({ theme }) => {
    const isDarkMode = useSettings((state) => state.isDarkMode);
    if (isDarkMode) {
      return theme.color.additional.alwaysBlack.value;
    } else {
      return theme.color.gray[100].value;
    }
  }};
  flex-shrink: 0;
`;
