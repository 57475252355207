import { entries } from "../store/entries";

export const findEntry = ({ name, type = "both" }) => {
  const entry = Array.from(entries.values()).filter((item) => {
    if (type === "both") return item.name === name;
    else return item.type === type && item.name === name;
  });
  if (entry) return entry[0];
  return undefined;
};
