import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useSettings = create(
  persist(
    (set) => ({
      isDarkMode: true,
      setIsDarkMode: (value) => set({ isDarkMode: value }),
    }),
    { name: "bcv2-settings" }
  )
);
