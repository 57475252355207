import { create } from "zustand";

export const useSidebar = create((set) => ({
  name: null,
  content: null,
  width: "456",
  setContent: (content) => set({ content }),
  setName: (name) => set({ name }),
  setSidebar: (content, name) => {
    return set({ content: content, name: name });
  },
  closeSidebar: () => set({ content: null, name: null }),
  setWidth: (value) => set({ width: value }),
}));
