import styled from "styled-components";
import { useUser } from "../store/useUser";
import { Text } from "./Text.jsx";
import { SidebarHeader } from "./SidebarHeader";
import { SecondaryButton } from "cdk-radial";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "store/useSidebar";

const ProfilePic = styled(({ className, pic }) => (
  <img className={`ProfilePic ${className}`} src={pic} alt="" />
))`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const SignOut = styled(SecondaryButton)`
  margin-left: 16px;
`;

const Row = styled.div``;

export const StyledProfile = styled(({ className }) => {
  const { setCurrentUser, user } = useUser((state) => ({
    user: state.users[state.currentUser],
    setCurrentUser: state.setCurrentUser,
  }));
  // const currentUser = useUser((state) => state.currentUser);
  // const user = useUser((state) => state.users[currentUser]);
  const navigate = useNavigate();
  const closeSidebar = useSidebar((state) => state.closeSidebar);

  const handleSignOut = () => {
    closeSidebar();
    setCurrentUser(0);
    navigate("/login");
  };

  return (
    <div className={`Profile ${className}`}>
      <SidebarHeader title="User Profile" />
      <section>
        <ProfilePic pic={user.profileImage} />
        <div>
          <Text.Heading2 bold>{user.name}</Text.Heading2>
          <Text.Body1>{user.storeName}</Text.Body1>
        </div>
      </section>
      <section>
        <Text.Heading2 bold>Details</Text.Heading2>
        <Row>
          <Text.Body1>Employee ID</Text.Body1>
          <Text.Body1>{user.employeeId}</Text.Body1>
        </Row>
        <Row>
          <Text.Body1>Store #</Text.Body1>
          <Text.Body1>{user.storeId}</Text.Body1>
        </Row>
      </section>
      <section>
        <Text.Heading2 bold>Contact Information</Text.Heading2>
        <Row>
          <Text.Body1>Phone</Text.Body1>
          <Text.Body1>{user.phone}</Text.Body1>
        </Row>
        <Row>
          <Text.Body1>Email</Text.Body1>
          <Text.Body1>{user.email}</Text.Body1>
        </Row>
      </section>
      <SignOut text="Sign out" onClick={handleSignOut} />
    </div>
  );
})`
  ${ProfilePic} {
    margin-left: 16px;
  }
  section {
    padding: 16px;
    ${Row} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 8px;
    }
  }
  section:first-of-type {
    display: flex;
    gap: 16px;
  }
`;

export const Profile = (props) => <StyledProfile {...props} />;
