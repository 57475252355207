import { ReactComponent as LogoDriveLight } from "../svgs/CDK_Drive_Logo.svg";
import { ReactComponent as LogoDriveDark } from "../svgs/CDK_Drive_Logo_White.svg";
import { ReactComponent as LogoSaltyLight } from "../svgs/cdk_salty.svg";
import { ReactComponent as LogoSaltyDark } from "../svgs/cdk_salty_White.svg";
import { ReactComponent as LogoOnePayLight } from "../svgs/CDK_OnePay_Logo1.svg";
import { ReactComponent as LogoOnePayDark } from "../svgs/CDK_OnePay_Logo_White.svg";

const banners = [
  {
    headline:
      "We've noticed you might be interested in Additional flexibility in making secure supplier payments with the introduction of Virtual Card Payments",
    description:
      "An integrated tool that allows managers to optimize, back-test, and manage compensation plans and spiffs.",
    svg: { light: <LogoDriveLight />, dark: <LogoDriveDark /> },
    url: "",
  },
  {
    headline: "We thought you might be interested",
    description:
      "CDK OnePay delivers a seamless payment experience in store, through mobile or online for your customers",
    svg: { light: <LogoOnePayLight />, dark: <LogoOnePayDark /> },
    url: "",
  },
  {
    headline:
      "We've noticed you might be interested in our insurance solutions",
    description:
      "Our unique Embedded Insurance platform researches prices and policies across multiple carriers to provide a custom-built policy right when you need it most.",
    svg: { light: <LogoSaltyLight />, dark: <LogoSaltyDark /> },
    url: "",
  },
];

export const useBanners = () => banners;
