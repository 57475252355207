import { create } from "zustand";

export const useOpenApps = create((set) => ({
  openApps: [],

  addOpenApp: (app) =>
    set((state) => {
      const temp = state.openApps.filter((item) => item.name !== app.name);
      return { openApps: [app, ...temp] };
    }),
  removeOpenApp: (index) => {
    set((state) => ({
      openApps: state.openApps.filter((item, itemIndex) => itemIndex !== index),
    }));
  },
  activateOpenApp: (app) => {
    set((state) => {
      const temp = state.openApps.filter(
        (item, itemIndex) => item.name !== app
      );
      return { openApps: [app, ...temp] };
    });
  },
}));
