import { css } from "styled-components";

import { focusOutlineColors, getHexToRgb } from "cdk-radial";

export const unselectedStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
    box-shadow: none;
  `,
  default: css`
    outline: none;
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.state.default.focus.value};
    box-shadow: inset 0 0 0 4px
      ${({ theme }) => focusOutlineColors.getDark(theme)};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
  `,
};

const selectedStyles = {
  active: css`
    background-color: ${({ theme }) =>
      `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.25)`};
    box-shadow: none;
  `,
  default: css`
    && {
      background-color: ${({ theme }) => theme.color.state.primary.hover.value};
      outline: none;
    }
  `,
  focus: css`
    background-color: ${({ theme }) =>
      `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.3)`};
    box-shadow: inset 0 0 0 4px
      ${({ theme }) => focusOutlineColors.getPrimary(theme)};
  `,
  hover: css`
    background-color: ${({ theme }) =>
      `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.3)`};
  `,
};

export const menuGetStateStyles = (isSelected) =>
  isSelected ? selectedStyles : unselectedStyles;
