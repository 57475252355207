export const getColorFromGroup = (group) => {
  switch (group) {
    case "dms":
      return "#315c2c";
    case "crm":
      return "#cf392b";
    case "digital retailing":
      return "#000000";
    case "CDK Drive SaaS":
      return "#315c2c";
    case "embedded insurance":
      return "#42b5e1";
    case "fixed ops":
      return "#0374dd";
    case "fortellis":
      return "#6a2875";
    case "intelligence":
      return "#6a2875";
    case "it solutions":
      return "#1d809a";
    case "wholesaling":
      return "#000000";
    default:
      return "#000";
  }
};
