import styled from "styled-components";
import { AppIcon } from "./AppIcon";
import { AppName } from "./AppName";
import { SimpleEntryGroup } from "./EntryGroup/EntryGroup";
import { Entries } from "./EntryGroup/Entries";
import { StyledEntryCard } from "./EntryCard/EntryCard";

export const StyledAccordionOpenWorkflow = styled(
  ({ className, entry, workflowsList, actionMenuOptions }) => {
    const { initials, group, name } = entry;

    return (
      <div className={`WorkflowAccordionOpen ${className}`}>
        <header>
          <AppIcon initials={initials} group={group} />
          <AppName>{name}</AppName>
        </header>
        <SimpleEntryGroup
          customKey="My Workflows"
          entries={workflowsList}
          hasDescription={true}
          hasFavorite={false}
          hasActionMenu={true}
          actionMenuOptions={actionMenuOptions}
          hasTitle={false}
          hasIcon={false}
        />
      </div>
    );
  }
)`
  header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 16px;
    color: ${({ theme }) => theme.color.additional.dark.value};
  }
  padding: 16px;
  ${Entries} {
    gap: 24px;
  }
  ${StyledEntryCard} {
    color: ${({ theme }) => theme.color.additional.dark.value};
    header {
      padding-bottom: 0;
    }
  }
`;

export const AccordionOpenWorkflow = (props) => (
  <StyledAccordionOpenWorkflow {...props} />
);
